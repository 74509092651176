import React from "react"

import Layout from "../components/layout-pt"
import SEO from "../components/seo"
import "@fortawesome/fontawesome-svg-core/styles.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Sobre nós" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Camping Silveira</h1>
          <h2 class="subtitle">
            Sejam bem-vindos os apreciadores de calma e de natureza!
          </h2>
        </div>
      </div>
    </section>
    <section class="hero has-background-info">
      <div class="hero-body">
        <div class="container">
          <h2 class="subtitle has-text-white has-text-weight-bold">
            Aberto entre dia 1 de Maio e 15 de Setembro
          </h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column is-6">
          <div class="content">
            <p>
              Entre o mar e a serra, a 100 km de Lisboa e 3 km de Alcobaça, a
              Silveira é um pequeno campismo rural sem animação, sem piscina nem
              alugueres, situado numa antiga quinta de 8 hectares.{" "}
            </p>
            <p>
              O parque de campismo foi instalado na sua parte arborizada
              (principalmente eucaliptos e sobreiros).{" "}
            </p>
            <p>
              Os 19 alvéolos estão separados uns dos outros pela vegetação
              natural. Temos uma capacidade máxima de 60 pessoas, e estamos
              abertos de 1 de Maio a 15 de Setembro.{" "}
            </p>
            <p>À disposição dos campistas:</p>
            <ul type="1">
              <li>Máquina de lavar a roupa</li>
              <li>Churrasqueiras</li>
              <li>Área coberta, com mesas e bancos</li>
              <li>Frigorífico</li>
              <li>Mesa de ping-pong</li>
              <li>Campo de badmington</li>
              <li>Baloiços</li>
              <li>Uma pequena biblioteca e alguns brinquedos</li>
              <li>
                <strong>
                  Sete hectares de natureza onde poderá passear ou os seus
                  filhos brincar
                </strong>
              </li>
              <li>Wi-Fi grátis</li>
            </ul>
          </div>
        </div>
        <div class="column">
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/convivio_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Instalações do parque
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/eg_plot_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Alvéolo do parque
            </p>
          </figure>
          <figure class="image" style={{ "margin-bottom": "1rem" }}>
            <img src="/img/photos/nazare_1_m.png" alt="alt" />
            <p class="is-size-7" style={{ "text-align": "center" }}>
              Praia da Nazaré (a 15km do parque)
            </p>
          </figure>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
